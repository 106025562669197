import React, { useState, useEffect, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { Link, useNavigate } from 'react-router-dom';
import FeaturesList, { WarningItem } from '../components/FeaturesList';
import SEO from '../components/SEO';
import { getDNS, createTest, getLastTests } from '../api/services';
import { isValidUrl } from '../utils/functions';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [ error, setError ] = useState<string | null>(null);
  const [ url, setUrl ] = useState('');
  const [ formErrors, setFormErrors ] = useState<null | { url: string }>(null);
  const [ tests, setTests ] = useState<any>(null);

  const onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    setFormErrors(null);
    setError(null);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    setFormErrors(null);

    if (form.checkValidity()) {
      const urlWithScheme = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

      if (!isValidUrl(urlWithScheme)) {
        setFormErrors({ url: 'URL address is not valid!' });
        return;
      }

      const dns = await getDNS((new URL(urlWithScheme).hostname));

      if ('error' in dns) {
        setFormErrors({ url: 'No DNS records for this domain!' });
        return;
      }

      createTest(urlWithScheme).then((response) => {
        navigate(`/report/${response.data.test.id}`);    
      }).catch(error => {
        setError(error.message);
      });
    }
  }

  useEffect(() => {
    getLastTests().then((response: any) => setTests(response));
  }, []);

  return (
    <SEO title="" description="">
      <Container>
        <Row style={{ marginTop: '20%' }}>
          <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
            <h1 className="text-center">Pentest Tool LITE</h1>
            {
              error !== null ? (
                <Alert variant="danger">{error}</Alert>
              ) : null
            }
            <div className="mt-4">
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="url">
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Enter URL"
                    value={url}
                    onChange={onUrlChange}
                    isInvalid={formErrors !== null}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    { formErrors !== null ? formErrors.url : '' }
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="text-center">
                  <Button variant="outline-primary" type="submit">
                    Run tests
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: '#F2F3F4', marginTop: '10%', paddingTop: 40, paddingBottom: 40 }}>
        <Container>
          <Row>
            <Col>
              <h2 className="text-center">Recent tests</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 12 }} className="recent-tests-container">
              {
                tests !== null && tests.map((test: any, index: number) => (
                  <Fragment key={test.id}>
                    <p key={test.id} className="position-relative overflow-hidden">
                      <span style={{ marginLeft: '1rem' }}>
                        <Link to={`/report/${test.id}`}>{test.url}</Link>
                      </span>
                      <span style={{ position: 'absolute', right: 0, top: 0, backgroundColor: 'rgb(242, 243, 244)' }}>
                        <span className="text-success recent-tests-status-count text-center">{test.successCount}</span> |
                        <span className="text-warning recent-tests-status-count text-center">{test.warningCount}</span> |
                        <span className="text-error recent-tests-status-count text-center">{test.errorCount}</span>
                      </span>
                    </p>
                    {
                      index + 1 < tests.length ? (
                        <hr />
                      ) : null
                    }
                  </Fragment>
                ))
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-4 pt-4 pb-4">
        <Row>
          <Col>
            <h2 className="text-center">Features</h2>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={6} sm={6} md={6} lg={3}>
            <h5>Security</h5>
            <FeaturesList>
              <li>HTTPS</li>
              <li>HSTS</li>
              <li>FingerPrint</li>
              <li>SSL</li>
              <li>WebRisk</li>
              <li>Permissions-Policy</li>
              <li>Referrer-Policy</li>
              <li>X-Frame-Options</li>
              <li>X-XSS-Protection</li>
              <li>And more</li>
            </FeaturesList>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3}>
            <h5>DNS</h5>
            <FeaturesList>
              <li>Domain expiration</li>
              <li>DMARC</li>
              <WarningItem>SPF</WarningItem>
            </FeaturesList>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3}>
            <h5>HTML</h5>
            <FeaturesList>
              <li>JavaScripts</li>
              <li>CSS</li>
              <li>Anchors</li>
              <li>Images</li>
              <li>Generator</li>
            </FeaturesList>
          </Col>
          <Col xs={6} sm={6} md={6} lg={3}>
            <h5>SEO</h5>
            <FeaturesList>
              <li>Title</li>
              <li>H1</li>
              <li>Sitemap</li>
              <li>Robots</li>
              <WarningItem>Description</WarningItem>
              <WarningItem>Viewport</WarningItem>
              <WarningItem>OG tags</WarningItem>
              <WarningItem>Twitter (X) cards</WarningItem>
            </FeaturesList>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: '#F2F3F4', paddingTop: 40, paddingBottom: 40 }}>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center">Contributors</h2>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={3} md={3} sm={6} xs={12} className="mt-2">
            <a href="https://github.com/juffalow" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
              <Image src="https://github.com/juffalow.png" width="50" roundedCircle />
              <h4 style={{ display: 'inline', marginLeft: 10 }}>Matej Jellus</h4>
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="mt-2">
            <a href="https://github.com/vahanito" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
              <Image src="https://github.com/vahanito.png" width="50" roundedCircle />
              <h4 style={{ display: 'inline', marginLeft: 10 }}>Tomas Vahancik</h4>
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="mt-2">
            <a href="https://github.com/Kubik2000" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
              <Image src="https://github.com/Kubik2000.png" width="50" roundedCircle />
              <h4 style={{ display: 'inline', marginLeft: 10 }}>Jakub Drastich</h4>
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="mt-2">
            <a href="https://github.com/SjamonDaal" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
              <Image src="https://github.com/SjamonDaal.png" width="50" roundedCircle />
              <h4 style={{ display: 'inline', marginLeft: 10 }}>Djamon Staal</h4>
            </a>
          </Col>
        </Row>
        </Container>
      </div>
      <Container>
        <Row className="mt-4">
          <Col>
            <h2 className="text-center">About</h2>
            <p className="lead">Analyse your website and check most common server setting issues, see if all resources like images, JavaScript and CSS files are accessible and links point to existing pages.</p>
            <p>This application is wrapper over <a href="https://www.npmjs.com/package/pentest-tool-lite" target="_blank" rel="noreferrer">pentest-tool-lite</a> npm package and is running all tests in queue workers using <a href="https://github.com/OptimalBits/bull" target="_blank" rel="noreferrer">Bull</a>. You can read more about this project <a href="https://juffalow.com/docs/pentest-tool-lite/about">here</a> or about individual tests and how to fix them <a href="https://juffalow.com/docs/pentest-tool-lite/tests">here</a>. If you are missing any information, have new feature request, bug or want to contribute, please check the <a href="https://github.com/juffalow/pentest-tool-lite-com">GitHub</a> page.</p>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
}

export default Home;
