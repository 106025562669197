import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableResult from './TableResult';

interface Props {
  test: any;
  statusFilter: string[];
}

const TableReport: React.FC<Props> = ({ test, statusFilter }: Props) => {
  return (
    <>
      <Row className="mt-5">
        <Col>
          <h3 className="text-center mb-3">Security</h3>
          <TableResult tests={test.result.security.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
        </Col>
      </Row>
      {
        'dns' in test.result && test.result.dns.results.length > 0 ? (
          <Row className="mt-5">
            <Col>
              <h3 className="text-center mb-3">DNS</h3>
              <TableResult tests={test.result.dns.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
            </Col>
          </Row>
        ) : null
      }
      {
        'html' in test.result && test.result.dns.results.length > 0 ? (
          <Row className="mt-5">
            <Col>
              <h3 className="text-center mb-3">HTML</h3>
              <TableResult tests={test.result.html.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
            </Col>
          </Row>
        ) : null
      }
      {
        'seo' in test.result && test.result.seo.results.length > 0 ? (
          <Row className="mt-5">
            <Col>
              <h3 className="text-center mb-3">SEO</h3>
              <TableResult tests={test.result.seo.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
            </Col>
          </Row>
        ) : null
      }
      {
        'wordpress' in test.result &&  test.result.wordpress.status !== 'SUCCESS' ? (
          <Row className="mt-5">
            <Col>
              <h3 className="text-center mb-3">WordPress</h3>
              <TableResult tests={test.result.wordpress.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
            </Col>
          </Row>
        ) : null
      }
    </>
  );
}

export default TableReport;
