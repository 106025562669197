import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import BasicReport from './report/BasicReport';
import TableReport from './report/TableReport';
import JSONReport from './report/JSONReport';
import SEO from '../components/SEO';
import { getResult } from '../api/services';

const Report: React.FC = () => {
  const params = useParams();
  const [ test, setTest ] = useState<any>(null);
  const [ statusFilter, setStatusFilter ] = useState<string[]>([]);

  const toggleStatusFilter = (event: any): void => {
    const status: string = event.target.value;
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter((value: string) => value !== status));
    } else {
      setStatusFilter([...statusFilter, status]);
    }
  }; 

  useEffect(() => {
    getResult(params.id as string).then((response: any) => setTest(response));
  }, [ params.id ]);

  useEffect(() => {
    const interval = setInterval(() => {
      getResult(params.id as string).then((response: any) => {
        setTest(response);
        if (response.status !== 'running') {
          clearInterval(interval);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [ params.id ])

  if (test === null) {
    return (
      <SEO title="Report" description="">
        <Container>
          <Row>
            <Col>
              <h1>Report</h1>
            </Col>
          </Row>
        </Container>
      </SEO>
    );
  }

  return (
    <SEO title="Report" description="">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h1 className="text-center">Report for {(new URL(test.url)).hostname}</h1>
            <ListGroup variant="flush" className="mt-4">
              <ListGroup.Item><b>URL</b>: <a href={test.url} target="_blank" rel="noopener noreferrer">{test.url}</a></ListGroup.Item>
              <ListGroup.Item><b>Requested at</b>: {(new Date(Date.parse(test.createdAt))).toLocaleDateString()} {(new Date(Date.parse(test.createdAt))).toLocaleTimeString()}</ListGroup.Item>
              <ListGroup.Item><b>Status</b>: &nbsp;
                {
                  test.status === 'running' && ( <Badge bg="secondary">{test.status}</Badge> )
                }
                {
                  test.status === 'error' && ( <Badge bg="danger">{test.status}</Badge> )
                }
                {
                  test.status === 'done' && ( <Badge bg="success">{test.status}</Badge> )
                }
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>

        <h2 className="text-center mt-4">Results</h2>
        <Row xs="auto" className="mt-4">
          <ButtonGroup className="mx-auto">
            <ToggleButton
              id="success"
              type="checkbox"
              variant="outline-success"
              value="SUCCESS"
              checked={statusFilter.includes("SUCCESS")}
              onChange={toggleStatusFilter}
            >
              {test.successCount} Success
            </ToggleButton>
            <ToggleButton
              id="warning"
              type="checkbox"
              variant="outline-warning"
              value="WARNING"
              checked={statusFilter.includes("WARNING")}
              onChange={toggleStatusFilter}
            >
              {test.warningCount} Warning
            </ToggleButton>
            <ToggleButton
              id="critical"
              type="checkbox"
              variant="outline-danger"
              value="ERROR"
              checked={statusFilter.includes("ERROR")}
              onChange={toggleStatusFilter}
            >
              {test.errorCount} Critical
            </ToggleButton>
          </ButtonGroup>
        </Row>

        {
          test.status === 'done' ? (
            <Tabs defaultActiveKey="basic" id="fill-tab-example" className="mt-5" fill>
              <Tab eventKey="basic" title="Basic">
                <BasicReport test={test} statusFilter={statusFilter} />
              </Tab>
              <Tab eventKey="table" title="Table">
                <TableReport test={test} statusFilter={statusFilter} />
              </Tab>
              <Tab eventKey="json" title="JSON">
                <JSONReport test={test} />
              </Tab>
            </Tabs>
          ) : null
        }
      </Container>
    </SEO>
  );
}

export default Report;
