import React from 'react';
import styles from './Result.module.css';

interface ResultProps {
  tests: any[];
  level: number;
  filteredStatuses: string[];
}

const isCurrentLevelVisible = (result: any, statuses: string[]) => {
  if ('results' in result) {
    return result.results.some((r:any) => isCurrentLevelVisible(r, statuses));
  }
  return statuses.length === 0 || statuses.includes(result.status);
}

const Result: React.FC<ResultProps> = ({ tests, level, filteredStatuses }: ResultProps) => (
  <>
    <ul className={styles.result}>
      {
        tests
          .filter((result: any) => isCurrentLevelVisible(result, filteredStatuses))
          .map((result: any, index: number) => (
          <li key={index}>
            <div>
              {
                level === 1 ? (
                  <h4 className={styles[result.status.toLowerCase()]}>{result.title}</h4>
                ) : null
              }
              {
                level === 2 ? (
                  <h5 className={styles[result.status.toLowerCase()]}>{result.title}</h5>
                ) : null
              }
              {
                level > 2 ? (
                  <h6 className={styles[result.status.toLowerCase()]}>{result.title}</h6>
                ) : null
              }
              <pre>{result.description}</pre>
              {
                'results' in result && result.results.length > 0 ? (
                  <Result tests={result.results} level={level + 1} filteredStatuses={filteredStatuses} />
                ) : null
              }
            </div>
          </li>
        ))
      }
    </ul>
  </>
);

export default Result;
